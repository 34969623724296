import React from 'react';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import { Dropdown, Menu } from 'antd';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import axios from '../../../../network/axios/$axios';
import { get } from 'lodash';

const centerStyle = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center'
};
const openJublia = () => {
  axios('getJublia').then((jubliaRes) => {
    console.log('jubliaRes', jubliaRes);
    let jubliaLink = get(jubliaRes, 'basic_details[0].login_url');
    if (jubliaLink) window.open(jubliaLink, '_blank');
  });
};

function MenuHorizontal(props) {
  const { navigation, language, userInfo, selectedModel } = props;
  const threeRoutes = [
    '/Landing3d',
    '/Lobby3d',
    '/ExpoHall3d',
    '/SocialLounge3d'
  ];
  const List = [];
  navigation?.map((item) => {
    // if (item.title === 'Expo Hall') {
    //     List.push(item);
    //     List.push({
    //         title: "Survey",
    //         href: "https://forms.gle/YX6P8gSFdMm6jaxy9",
    //         display_title: {
    //             "en-us": "Survey"
    //         }
    //     })
    // } else {
    List.push(item);
    // }
  });

  const menuItem = List.map((item, index) => {
    return (
      <Menu.Item key={item.title}>
        {item.title === 'Survey' ? (
          <a href={item.href} target="blank">
            {item.display_title && item.display_title[language]}
          </a>
        ) : (
          <Link
            to={
              selectedModel === '3d' && threeRoutes.includes(item.href + '3d')
                ? item.href + '3d'
                : item.href
            }
          >
            {item.display_title && item.display_title[language]}
          </Link>
        )}
      </Menu.Item>
    );
  });
  if (userInfo?.isJubliaUser) {
    menuItem.splice(
      -1,
      0,
      <Menu.Item key="jublia">
        <a onClick={() => openJublia()}>{i18next.t('translations.jublia')}</a>
      </Menu.Item>
    );
  }
  const urlCode = {
    'en-us': 'en',
    'zh-hans': 'sc',
    'zh-hant': 'tc'
  };
  const cvcfUrlCode = {
    'en-us': 'en/index',
    'zh-hans': 'sc/index',
    'zh-hant': 'tc/index'
  };
  const items = (
    <Menu>
      <Menu.Item key="https://cvcf.cyberport.hk/en/founderstage?m=0">
        <a
          onClick={() => {
            props.UPDATE_SELF_IFRAME_ITEM({
              linkType: 'iframe',
              href: `https://cvcf.cyberport.hk/${urlCode[language]}/founderstage?m=0`
            });
            props.history.push(`/embeddedPage`);
          }}
        >
          {i18next.t('translations.cfs')}
        </a>
      </Menu.Item>
      <Menu.Item key="https://cvcf.cyberport.hk/en/acknowledgement">
        <a
          onClick={() => {
            props.UPDATE_SELF_IFRAME_ITEM({
              linkType: 'iframe',
              href: `https://cvcf.cyberport.hk/${urlCode[language]}/acknowledgement?m=0`
            });
            props.history.push(`/embeddedPage`);
          }}
        >
          {i18next.t('translations.acknowledgement')}
        </a>
      </Menu.Item>

      <Menu.Item key="https://cvcf.cyberport.hk/">
        <a
          onClick={() => {
            props.UPDATE_SELF_IFRAME_ITEM({
              linkType: 'iframe',
              href: `https://cvcf.cyberport.hk/${cvcfUrlCode[language]}?m=0`
            });
            props.history.push(`/embeddedPage`);
          }}
        >
          {i18next.t('translations.cvcf')}
        </a>
      </Menu.Item>
      <Menu.Item key="https://cvcf.cyberport.hk/en/smartliving">
        <a
          onClick={() => {
            props.UPDATE_SELF_IFRAME_ITEM({
              linkType: 'iframe',
              href: `https://cvcf.cyberport.hk/${urlCode[language]}/smartliving?m=0`
            });
            props.history.push(`/embeddedPage`);
          }}
        >
          {i18next.t('translations.cslvd')}
        </a>
      </Menu.Item>
    </Menu>
  );

  const display_title = {
    'en-us': 'More',
    'zh-hans': '更多',
    'zh-hant': '更多'
  };

  return (
    <Menu
      mode="horizontal"
      theme="light"
      // style={centerStyle}
    >
      {menuItem}

      {/* <Menu.Item key='More'>
                <Dropdown overlay={items}>
                    <div>{display_title[language]}</div>
                </Dropdown>
            </Menu.Item> */}
    </Menu>
  );
}

const mapStateToProps = (state) => ({
  selectedModel: state.profile.selectedModel
});

const mapDispatchToProps = (dispatch) => ({
  UPDATE_SELF_IFRAME_ITEM: (item) => {
    dispatch({
      type: 'UPDATE_SELF_IFRAME_ITEM',
      data: item
    });
  }
});

const enhance = compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(MenuHorizontal);
