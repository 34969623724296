import React, { Component } from 'react';
import { Row, Col, message } from 'antd';
import './ExpoHallBooth.less';
import i18next from 'i18next';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import get from 'lodash/get';
import { connect } from 'react-redux';
import axios from '../../network/axios/$axios';
import NavigationBar from '../../components/NavigationBar';
import ClickArea from '../../components/ClickArea';
import { enterCurrentPage } from '../../Utils/Util';
import { BOOTH_CAHT_LOG_PUBLIC_CHART_USER } from '../../actions/LoginUserAction';
import ChatMessage from '../../components/ChatMessage';
import Booth3D from '../../3d/Booth3diframe';
import Message from '../../components/Message';
import ScheduleMeetingModal from '../BusinessMatching/components/ScheduleMeetingModal';
import BreadCrumbs from '../../components/BreadCrumbs';
import Game from '../../game/Game';

class ExpoHallBoothScreen extends Component {
  constructor(props) {
    super(props);
    // 解决跳转页面页面滚动位置错误问题
    window.scrollTo(0, 0);
    this.state = {
      aboutUsisModalVisible: false,
      chatModalVisible: false,
      linksisModalVisible: false,
      messageisModalVisible: false,
      brochuresisModalVisible: false,
      mediaisModalVisible: false,
      productisModalVisible: false,
      detail: {},
      presenters: {},
      boothDesign: {},
      chatSwitch: false,
      meetVisible: false,
      navBarItems: [],
      responsibleExhibitorId: "",
      responsibleExhibitorEmail: ""
    };
  }

  componentDidMount() {
    this.props.SHOW_BACK(true);
    axios('booths', { path: this.props.match.params.id })
      .then((response) => {
        console.log('/booths', { response, path: this.props.match.params.id });
        this.setState({ detail: response });
        axios('geExhibitortUser', {
          path:
            `?filter=${JSON.stringify({ ExhibitorId: response.ExhibitorId })}&sort={"_id":"ASC"}`
        }).then(res => {
          this.setState({ responsibleExhibitorId: get(res, 'data[0]._id'), responsibleExhibitorEmail: get(res, 'data[0].id') })
        })
        axios('boothDesign').then((res) => {
          console.log('/Design/Booth', res);
          const list = res.data[0].design;
          const arr = list.filter((obj) => obj.id === response.design);
          this.setState({ boothDesign: arr[0] });
        });
        axios('presenters').then((res) => {
          console.log('/Design/Host', res);
          const list = res.data[0].design;
          const arr = list.filter((obj) => obj.id === response.host);
          this.setState({ presenters: arr[0] });
        });
      })
      .catch((error) => {
        console.log(error);
      });
    // 获取navigationBarItems
    axios('boothNavBarItem', {
      path: `?filter={"BoothId":"${this.props.match.params.id}","ver":"2"}&sort={"seq":"ASC"}`
    })
      .then((response) => {
        console.log('/Booth/NavBarItem=', response);
        this.setState({ navBarItems: response.data })
      })
      .catch((error) => {
        console.log(error);
      });
    enterCurrentPage();
  }
  componentWillUnmount() {
    // 清空数据，判断是群聊/Booth
    this.props.BOOTH_CAHT_LOG_PUBLIC_CHART_USER({});
    this.props.SHOW_BACK(false);
  }
  //统计点击事件
  clickUpload = (fieldname) => {
    axios('activity', {
      data: {
        event: '[Click] Booth ' + fieldname
      }
    }).then((response) => {
      console.log('/activity', response);
    });
  };
  // 显示消息弹窗
  messageModalShow = () => {
    this.setState({
      messageisModalVisible: true
    });
    this.clickUpload('message');
  };
  // 隐藏消息弹窗
  messageModalHiddle = () => {
    this.setState({
      messageisModalVisible: false
    });
  };
  // 提交消息
  messageHandleOk = (valueMessage) => {
    if (!valueMessage) {
      message.warning('please fill out this field');
      return;
    }
    this.setState({
      messageisModalVisible: false
    });
    // "BoothId": <Booth._id>,
    // "ExhibitorId: <Booth.ExhibitorId>,
    // "message": <Message>
    const { ExhibitorId, _id } = this.state.detail;
    const userInfo = this.props.userInfo
    axios('EnquiryMessage', { data: { ExhibitorId, BoothId: _id, message: valueMessage, SenderId: userInfo._id } }).then((res) => {
      console.log('/Design/Host', res);
      console.log(this.state.responsibleExhibitorEmail)
      axios('email', {
        pathObj: { trigger: 'BoothEnquiryReceive' },
        data: {
          to: "a98ccp@gmail.com" || this.state.responsibleExhibitorEmail,
          data: { BoothId: _id, userId: userInfo._id }
        }
      })
    });
  };
  // 渲染底部操作按钮
  renderBottomBtn = (canEnquiry) => {
    const { detail, navBarItems } = this.state;
    const { primary, language, selectedModel } = this.props;
    const navBarItemsArray = [...navBarItems];
    if (selectedModel !== "2d" && canEnquiry) {
      navBarItemsArray.push({
        type: 'Enquiry', display_title: { 'en-us': "Enquiry", 'zh-hans': "询问", 'zh-hant': '詢問' },
        data: [{}]
      });
    }
    return (
      <NavigationBar
        navigationBar={navBarItemsArray.filter(em => Array.isArray(em.data) ? em.data.length > 0 : em.data)}
        language={language}
        primary={primary}
        data={detail}
        page="Booth"
        {...this.props}
      />
    );
  };
  //调整创建会议页面
  goCreateMeeting = () => {
    this.setState({ meetVisible: true });
    this.clickUpload('meet');
  };
  getOrJoinBoothChat = () => {
    // chartSwitch(false)
    this.chartSwitch(false);
    axios('getOrJoinBoothChat', {
      // data: {
      //   booth_id: this.props.match.params.id,
      // }
      data: {
        booth_id: this.props.match.params.id,
        user_id: this.state.responsibleExhibitorId

      }
    })
      .then((response) => {
        console.log('BOOTH_CAHT_LOG_PUBLIC_CHART_USER', response);
        this.props.BOOTH_CAHT_LOG_PUBLIC_CHART_USER(response);
        this.chartSwitch(true)
        // setPrivateId(response.id);
        // props.dispatchChatSwitch(true);
      });
  };
  chartSwitch = (swith) => {
    this.setState({
      chatSwitch: swith,
    })
  }
  // 显示聊天弹窗
  chatModalShow = () => {
    this.setState({
      chatModalVisible: true
    });
    this.clickUpload('chat');
    this.getOrJoinBoothChat();
  };
  // 隐藏聊天弹窗
  chatMadolHiddle = () => {
    this.setState({
      chatModalVisible: false
    });
  };
  // 隐藏创建会议弹窗
  handleCancel = () => {
    this.setState({
      meetVisible: false
    });
  }

  render() {
    const { detail, presenters, boothDesign, chatSwitch, messageisModalVisible, meetVisible, responsibleExhibitorId } =
      this.state;
    const { primary, language, selectedModel, menu } = this.props;
    const designData = get(detail, 'designData', {});
    console.log("michael", { detail, selectedModel })
    // 处理数据遍历banner图上的点击区域
    const clickables = get(boothDesign, 'links') || [];
    if (detail.designData) clickables.map((item) => {
      if (!item) return
      item.target = get(detail.designData[item.id], 'target');
      item.title = get(detail.designData[item.id], 'title');
      item.src =
        get(detail.designData[item.id], 'src');
      item.href = get(detail.designData[item.id], 'href');
      item.alt = get(detail.designData[item.id], 'alt');
      if (get(detail.designData[item.id], 'linkType')) {
        item.linkType = get(detail.designData[item.id], 'linkType');
      } else {
        const src = get(designData[item.id], 'src');
        if (src && src.indexOf('.mp4') > -1) {
          item.linkType = 'video';
        } else if (
          src &&
          (src.indexOf('.png') > -1 || src.indexOf('.jpg') > -1 || src.indexOf('.jpeg') > -1)
        ) {
          item.linkType = 'image';
        }
      }
    });

    const bubble = get(detail, 'bubble') || {};
    const isHidden = !bubble.canChat && !bubble.canEnquiry && !bubble.canMeet;
    let href = '/ExpoHall'
    if (window.location.href.includes("/Gallery")) {
      href = '/Gallery'
    }
    const arr = menu.filter(obj => obj.href === href);
    return (
      <div className="expohall-booth-page">
        <div style={{ position: 'absolute', zIndex: 999 }} className='page-container center'>
          <Row gutter={[32, 32]}>
            <BreadCrumbs
              breadCrumbParent={arr.length && (arr[0].display_title)[language]}
              breadCrumbActive={get(detail.display_name, language)}
            />
          </Row>
        </div>
        <div className='mobile-box' />
        {
          // detail.design === 7 ? (
          // <Booth3D data={designData} primary={primary} filePath="https://du2r3hvqku65.cloudfront.net/3d/booth-models/exhibition_stand/scene02.gltf" />
          // ) :
          (
            <Row justify='center' className="relative" style={{ backgroundColor: '#efefef' }}>
              {
                selectedModel === '2d' && <Col>
                  <div className="relative">
                    <img
                      className="bgImg"
                      src={get(boothDesign, 'background.src')}
                    />
                    {/* {(detail.design === 0 ||
                  detail.design === 1 ||
                  detail.design === 2 ||
                  detail.design === 3) && ( */}
                    <img
                      className="peaple"
                      src={get(presenters, 'src')}
                    />
                    <ClickArea
                      clickables={clickables}
                      bgwidth={get(boothDesign, 'background.width')}
                      bgheight={get(boothDesign, 'background.height')}
                      primary={primary}
                      language={language}
                      is2D={true}
                      page="Booth"
                    />
                  </div>
                  {!isHidden && (
                    <Col
                      className="tooltipsterBox center chat-bubble"
                      style={{
                        textAlign: 'center'
                      }}
                    >
                      <div className="pB10 bold">
                        <span style={{ fontSize: '14px', color: '#000000' }}>
                          {get(get(detail, 'bubble.display_text'), language)}
                        </span>
                      </div>
                      <Row justify="center">
                        {bubble.canChat && (
                          <Col
                            span="8"
                            className="pointer"
                            style={
                              bubble.canEnquiry || bubble.canMeet
                                ? { borderRight: '1px solid #dee2e6' }
                                : {}
                            }
                            onClick={this.chatModalShow}
                          >
                            <div className="pB10">
                              <span className="remix-icon" style={{ fontSize: '16px' }}>
                                <i className="ri-chat-smile-fill" />
                              </span>
                            </div>
                            <div style={{ fontSize: '13px' }}>
                              {i18next.t('translations.chat')}
                            </div>
                          </Col>
                        )}
                        {bubble.canEnquiry && (
                          <Col
                            span="8"
                            className="pointer"
                            style={
                              bubble.canMeet
                                ? { borderRight: '1px solid #dee2e6' }
                                : {}
                            }
                            onClick={this.messageModalShow}
                          >
                            <div className="pB10">
                              <span className="remix-icon" style={{ fontSize: '16px' }}>
                                <i className="ri-mail-line" />
                              </span>
                            </div>
                            <div style={{ fontSize: '13px' }}>
                              {i18next.t('translations.enquiry-message')}
                            </div>
                          </Col>
                        )}
                        {bubble.canMeet && (
                          <Col
                            span="8"
                            className="pointer"
                            onClick={() => this.goCreateMeeting()}
                          >
                            <div className="pB10">
                              <span className="remix-icon" style={{ fontSize: '16px' }}>
                                <i className="ri-calendar-2-fill" />
                              </span>
                            </div>
                            <div style={{ fontSize: '13px' }}>
                              {i18next.t('translations.meet')}
                            </div>
                          </Col>
                        )}
                      </Row>
                      <div
                        className="triangleDown"
                        style={{
                          borderTopColor: '#ffffff',
                          left:
                            detail.design === 4 || detail.design === 6 ? '50%' : '0'
                        }}
                      />
                    </Col>
                  )}
                </Col>
              }
              {selectedModel !== "2d" && detail?.design && <Booth3D data={designData || {}} primary={primary} id={detail._id} design={detail.design} />}
            </Row>
          )}
        {this.renderBottomBtn(bubble.canEnquiry)}
        <Message
          isModalVisible={messageisModalVisible}
          handleCancel={this.messageModalHiddle}
          handleOk={this.messageHandleOk}
          primary={primary}
        />
        <ScheduleMeetingModal isModalVisible={meetVisible} selectedItem={{ _id: responsibleExhibitorId }} handleCancel={() => this.handleCancel()} primary={primary} {...this.props} />
        {chatSwitch && <ChatMessage close={() => {
          this.chartSwitch(false);
          // 清空数据，判断是群聊/Booth
          this.props.BOOTH_CAHT_LOG_PUBLIC_CHART_USER({});
        }} />}
        <Game link={'#/ExpoHall/Booth/' + this.props.match.params.id} />
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  userInfo: state.loginUserInfo.userInfo,
  primary: state.profile.primary,
  language: state.profile.language,
  selectedModel: state.profile.selectedModel,
  menu: state.profile.menu
});
const mapDispatchToProps = (dispatch) => ({
  BOOTH_CAHT_LOG_PUBLIC_CHART_USER: (data) => {
    dispatch(BOOTH_CAHT_LOG_PUBLIC_CHART_USER(data));
  },
  SHOW_BACK: (data) => {
    dispatch({
      type: 'SHOW_BACK',
      data: data
    });
  },
});

const enhance = compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(ExpoHallBoothScreen);
