import React, { Component } from 'react';
import { Modal, Row } from 'antd';
import Zoom from '../components/Zoom';
import { CloseOutlined } from '@ant-design/icons';
// import * as THREE from 'three';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
// import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
// import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
// import nipplejs from 'nipplejs';
import './style.css';
import GameExternal from '../game/GameExternal';
// import keyState from "./key-state.js"
// import paintingInfo from './paintingInfo.js';
// import $ from 'jquery'
import { connect } from 'react-redux';
import apiAxios from '../network/axios/$axios';
class Booth3d extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: "",
      visible: false,
      linkHref: "",
      linkType: "",
      coinModelVisible: false

    }
    this.handleMessage = this.handleMessage.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
  }
  componentWillUnmount() {
    delete this.container
    window.removeEventListener("message", this.handleMessage)
    delete this.handleMessage
    delete this.handleCancel
  }
  componentDidMount() {
    // this.init3d({
    //   '3DUrl': this.props.url || 'https://d1a370nemizbjq.cloudfront.net/4f3f8eda-d682-4d21-ab14-074c395ab49c.glb'
    // })
    const { id, design } = this.props;
    const resourceId = id == "infodesk" ? "infodesk" : (design == -1 ? id : `design${design}`);
    window.addEventListener("message", this.handleMessage)
    apiAxios('threeD', { params: { filter: { resourceId } } })
      .then((response) => {
        let model = response.data[0];
        if (response.data.length > 1) {
          model = response.data.find(em => em.resourceId == id) || model
        }
        let q = new URLSearchParams({
          booth: (model.link),
          bg: "#EEF1F3",
          ...model.settings
        })
        const src = `https://ive.chefdigital.io/upload/threeD/newBooth.html?${q.toString()}`

        this.setState({ src })

      })
      .catch((error) => {
        console.log(error);
      });

  }

  handleMessage(event) {
    try {
      let data = JSON.parse(event.data)

      switch (data.type) {
        case "Booth":
          const msg = JSON.stringify({ type: "BoothData", data: { designData: this.props.data } })
          this.container.contentWindow.postMessage(msg, { targetOrigin: "*" })
          break;
        case "Media":
          const item = this.props.data[data.id]
          console.log("Media", this.props.data);
          if (!item) break;
          apiAxios('activity', {
            data: {
              event: '[Click] Booth3D ' + this.props.data.name || 'infodesk'
            }
          })
          if (item.target === "_blank") {
            window.open(item.href || item.src, "_blank")
            break;
          }
          if (item.target === '_self') {
            // 新页面打开iframe形式
            this.props.UPDATE_SELF_IFRAME_ITEM(item);
            this.props.history.push(`/embeddedPage`);
            return;
          }
          // 保存内容类型：linkType供popup使用, 暫時有iframe, zoom, video, image, 如果沒有設置默認為用iframe直接打開
          let updateState = {
            linkType: item.linkType,
          }
          if (item.target === '_popup') {
            updateState.visible = true;
            // landing没有src
            updateState.linkHref = (item.src || item.href);
            // setTarget('_popup');
          } else if (
            item.linkType === 'zoom' ||
            item.linkType === undefined
          ) {
            updateState.visible = true;
            // landing没有src
            updateState.linkHref = (item.href);
            // setTarget('_zoom');
          }
          if (item.linkType === 'video') {
            const video = document.getElementById('video');
            video.play();
          }

          this.setState(updateState)
          break;
        case "BoothLoaded":
          apiAxios('gameThreeD', { params: { filter: { "data.group": this.props.id } } }).then((res) => {
            console.log("threedcoin", { res, params: { filter: { "data.group": this.props.id } } })
            const { gameItems, record } = res
            const msg = JSON.stringify({ type: "CoinData", data: gameItems.map(em => ({ ref: em.data.ref, claimed: record.find(r => r.itemId == em._id)?.claimed || false, _id: em._id })) })
            this.container.contentWindow.postMessage(msg, { targetOrigin: "*" })

          })
          break;
        case "Hit":
          const hitmsg = JSON.stringify({ type: "Bypass", data: data.id })
          this.container.contentWindow.postMessage(hitmsg, { targetOrigin: "*" })
          this.setState({ coinModelVisible: true })
          break;
      }
    }
    catch (e) {
      console.log(event.data)
    }
  }

  handleCoinCancel = () => {
    this.setState({ coinModelVisible: false })
  }
  handleCancel() {
    this.setState({ visible: false });
    if (this.state.linkType === 'video') {
      const video = document.getElementById('video');
      video.pause();
    }
  };
  render() {
    const { visible, linkHref, linkType, coinModelVisible } = this.state
    return <><iframe ref={e => {
      this.container = e;
    }} id={this.props.id} src={this.state.src} style={{ width: '100%', height: window.innerHeight / 2 > 800 ? window.innerHeight / 2 : 800 }} scrolling="no" frameBorder="0" title='iframe' ></iframe>

      <Modal
        visible={visible}
        width={800}
        title={null}
        footer={null}
        closeIcon={<CloseOutlined style={{ color: '#2d3436' }} />}
        onCancel={this.handleCancel}
      >
        {linkType === 'zoom' ||
          linkType === 'iframe' ? (
          <Zoom link={linkHref} />
        ) : (
          <Row align="middle">
            {linkType === 'video' ? (
              <video
                id="video"
                controls
                autoPlay
                playsInline //解决手机端无法自动播放功能
                loop
                style={{ width: '100%', height: '100%' }}
                src={linkHref}
              />
            ) : (
              <img src={linkHref} style={{ width: '100%', height: '100%' }} />
            )}
          </Row>
        )}
      </Modal>
      {coinModelVisible && <GameExternal isModalVisible={coinModelVisible} handleCancel={this.handleCoinCancel} />}
    </>
  }
}

const mapStateToProps = (state) => ({
  url: state.loginUserInfo.url,
  userInfo: state.loginUserInfo.userInfo
});

const mapDispatchToProps = (dispatch) => ({
  UPDATE_SELF_IFRAME_ITEM: (item) => {
    dispatch({
      type: 'UPDATE_SELF_IFRAME_ITEM',
      data: item
    });
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Booth3d);
