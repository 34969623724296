import {
    SEND_WS_MSG,
    CLEAR_WS_MSG,
    LOCATION_ONLINE_USERS
} from '../constants/ActionTypes';

const initialState = {
    message: "",
    online_users: []
};

const webSocket = (state = initialState, action) => {
    switch (action.type) {
        case SEND_WS_MSG:
            return Object.assign({}, state, {
                message: action.data
            })
        case CLEAR_WS_MSG:
            return Object.assign({}, initialState);
        case LOCATION_ONLINE_USERS:
            return Object.assign({}, state, {
                online_users: action.data
            })
        default:
            return state;
    }
};

export default webSocket;
