import React, { Component } from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { message, Row } from 'antd';
// import { Link } from 'react-router-dom'
import Message from '../../components/Message';
import axios from '../../network/axios/$axios';
import get from 'lodash/get';
import NavigationBar from '../../components/NavigationBar';
import { enterCurrentPage } from '../../Utils/Util';
import ChatMessage from '../../components/ChatMessage';
import { BOOTH_CAHT_LOG_PUBLIC_CHART_USER } from '../../actions/LoginUserAction';
import './HelpDesk.less';
import i18next from 'i18next';
// import Booth3D from '../../3d/Booth3d';
import Booth3D from '../../3d/Booth3diframe';
import { isEmpty } from 'lodash';
import BreadCrumbs from '../../components/BreadCrumbs';
import Game from '../../game/Game';
import ClickArea from '../../components/ClickArea';

class HelpDesk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageisModalVisible: false,
      detail: {},
      chatSwitch: false,
      id: null,
      navBarItems: []
    };
  }
  componentDidMount() {
    axios('infoPage')
      .then((response) => {
        this.setState({ detail: response.data[0], id: response.data[0]._id });
      })
      .catch((error) => {
        console.log(error);
      });
    // 获取navigationBarItems
    axios('boothNavBarItem', {
      path: `?filter={"BoothId":"infodesk","ver":"2"}&sort={"seq":"ASC"}`
    })
      .then((response) => {
        const list = (response.data).filter(em => Array.isArray(em.data) ? em.data.length > 0 : em.data)
        this.setState({
          navBarItems: [...list, {
            type: 'Enquiry', display_title: { 'en-us': "Enquiry", 'zh-hans': "询问", 'zh-hant': '詢問' }
          }]
        })
      })
      .catch((error) => {
        console.log(error);
      });
    enterCurrentPage();
  }
  componentWillUnmount() {
    // 清空数据，判断是群聊/Booth
    this.props.BOOTH_CAHT_LOG_PUBLIC_CHART_USER({});
  }
  // 发message
  messageshowModal = () => {
    this.setState({
      messageisModalVisible: true
    });
  };
  messagehandleCancel = () => {
    this.setState({
      messageisModalVisible: false
    });
  };
  messageHandleOk = (valueMessage) => {
    console.log(valueMessage);
    if (!valueMessage) {
      message.warning(i18next.t('translations.fill-field'));
      return;
    }
    this.setState({
      messageisModalVisible: false
    });
    message.success(i18next.t('translations.send-success'));
  };
  // 显示聊天弹窗
  chatModalShow = () => {
    this.clickUpload('chat');
    this.getOrJoinBoothChat();
  };
  //统计点击事件
  clickUpload = (fieldname) => {
    axios('activity', {
      data: {
        event: '[Click] Booth ' + fieldname
      }
    }).then((response) => {
      console.log('/activity', response);
    });
  };
  getOrJoinBoothChat = () => {
    this.chartSwitch(false);
    axios('getOrJoinBoothChat', {
      // data: {
      //   booth_id: this.state.id,
      // }
      data: {
        user_id: this.state.id,
      }
    })
      .then((response) => {
        console.log('BOOTH_CAHT_LOG_PUBLIC_CHART_USER', response);
        this.props.BOOTH_CAHT_LOG_PUBLIC_CHART_USER(response);
        this.chartSwitch(true)
      });
  };
  chartSwitch = (swith) => {
    this.setState({
      chatSwitch: swith,
    })
  }

  render() {
    const { messageisModalVisible, detail, chatSwitch, navBarItems } = this.state;
    const { language, primary, location, menu, selectedModel } = this.props;
    const token = localStorage.getItem('token');
    // 处理数据遍历banner图上的点击区域
    // const navigationBar = get(detail, 'data.navigationBar') || [];
    const links = get(detail, 'data.links') || [];
    const designData = {};
    const iframeData = {}
    const clickables = [];
    links.map(item => {
      designData[item.alt] = item;
      iframeData[item.alt] = item
      iframeData[item.alt].src = item.background?.src


      clickables.push({
        ...item,
        src: item.background.src,
      })
    })
    clickables.map((item) => {
      const src = get(item, 'src');
      if (src && src.indexOf('.mp4') > -1) {
        item.linkType = 'video';
      } else if (
        src &&
        (src.indexOf('.png') > -1 || src.indexOf('.jpg') > -1 || src.indexOf('.jpeg') > -1)
      ) {
        item.linkType = 'image';
      }
    });
    const pathname = location.pathname;
    const arr = menu.filter(obj => obj.href === pathname);
    return (
      <div className="help-desk" >
        <div style={{ position: 'absolute', zIndex: 999 }} className='page-container center'>
          <Row gutter={[32, 32]}>
            <BreadCrumbs
              breadCrumbActive={arr.length && (arr[0].display_title)[language]}
            />
          </Row>
        </div>
        {false && selectedModel === '2d' ? <div className="relative">
          <img
            className="bgImg"
            src={get(detail, 'data.background.src')}
          />
          <ClickArea
            clickables={clickables}
            bgwidth={get(detail, 'data.background.width')}
            bgheight={get(detail, 'data.background.height')}
            primary={primary}
            language={language}
            is2D={true}
            page="Booth"
          />
        </div> : (!isEmpty(detail) && <Booth3D data={iframeData} primary={primary} id="infodesk" />)}

        <NavigationBar
          data={detail.data}
          navigationBar={navBarItems}
          language={language}
          primary={primary}
          page="HelpDesk"
          handleOk={this.chatModalShow}
          {...this.props}
        />
        <Message
          isModalVisible={messageisModalVisible}
          handleCancel={this.messagehandleCancel}
          handleOk={this.messageHandleOk}
          primary={primary}
        />
        {
          chatSwitch && <ChatMessage close={() => {
            this.chartSwitch(false);
            // 清空数据，判断是群聊/Booth
            this.props.BOOTH_CAHT_LOG_PUBLIC_CHART_USER({});
          }} />
        }
        <Game link='#/InfoPage' />
      </div >
    );
  }
}

const mapStateToProps = (state) => ({
  primary: state.profile.primary,
  language: state.profile.language,
  menu: state.profile.menu,
  userInfo: state.loginUserInfo.userInfo,
  selectedModel: state.profile.selectedModel,
});

const mapDispatchToProps = (dispatch) => ({
  BOOTH_CAHT_LOG_PUBLIC_CHART_USER: (data) => {
    dispatch(BOOTH_CAHT_LOG_PUBLIC_CHART_USER(data));
  },
});

const enhance = compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(HelpDesk);
