import * as types from '../constants/ActionTypes';
import { requestAPI } from '../network/RequestUtils';
import axios from '../network/axios/$axios';
import { message } from 'antd';
import i18next from 'i18next';
import { changeLanguage } from './ProfileAction';
const JWT = require('jsonwebtoken');
const CODES = {
  'en-us': ['en', 'en-us'],
  'zh-Hant': ['hk', 'zh-hant'],
  'zh-Hans': ['zh', 'zh-hans']
};
// getOrCreateChatUser, getOrJoinPublicChat
const initChatRequest = (dispatch) => {
  axios('getOrCreateChatUser', { params: {} })
    .then((response) => {
      // console.log('getOrCreateChatUser', response)
      // 保存数据
      // this.props.LOG_CHART_USER(response);
      dispatch(LOG_CHART_USER(response));

    });
  axios('getOrJoinPublicChat', { params: {} })
    .then((response) => {
      // console.log('getOrJoinPublicChat', response)
      // this.props.LOG_PUBLIC_CHART_USER(response);
      dispatch(LOG_PUBLIC_CHART_USER(response));
    });
};
// 根据时区id，查询当前时区
export function getTimezone(zoneCode) {
  return (dispatch) => {
    axios('registrationFormChoices', {
      path: zoneCode
    }).then((res) => {
      console.log('/registrationFormChoices', res);
      // name: '(UTC+08:00) Ulaanbaatar'
      // 处理时区数字
      let zoneNumber = res.name ? parseFloat(res.name.split('UTC')[1]) : '';
      dispatch({
        type: types.TIMEZONE,
        data: zoneNumber
      });
    });
  };
}
//用户注册
export function registerFetch(data, cb) {
  const { pass, repeatPass } = data;
  delete data.pass;
  delete data.repeatPass;
  console.log('registerFetch', data);
  return (dispatch) => {
    // 目的是为了外面能then收到里面的resolve
    axios('register', {
      // data: { username: data.email, email: data.email, password: data.password }
      data: {
        id: data.email,
        pass: pass,
        repeatPass: repeatPass,
        language: data.language,
        info: data
      }
    })
      .then((response) => {
        console.log('/register', response);
        axios('login', { data: { id: data.email, pass: pass } })
          .then((res) => {
            if (response && response !== "false") {
              const decoded = JWT.decode(res);
              axios('getUserDetail', { path: decoded._id })
                .then((res) => {
                  dispatch({
                    type: types.FETCH_LOGIN,
                    data: {
                      jwt: response,
                      user: {
                        _id: res._id,
                        username: res.info.username || res.id,
                        email: res.id,
                        firstname: res.info.firstname || "",
                        lastname: res.info.lastname || "",
                        ...res.info
                      },
                      isGuestLogin: false,
                      role: res.role,
                      url: res['3DUrl']
                    }
                  });
                  dispatch(getTimezone(res.info.timezone));
                  cb && cb(response);
                  initChatRequest(dispatch);
                })
                .catch((error) => {
                  console.log(error);
                });
              // cb(response);
            } else {
              message.error('请重新尝试');
            }
          })
          .catch((error) => {
            console.log(error);
          });
        // cb(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
//游客身份登录自动注册
export function autoRegisterFetch(data, cb) {
  console.log('autoRegisterFetch', data);
  const { pass, repeatPass } = data;
  delete data.pass;
  delete data.repeatPass;
  return (dispatch) => {
    // 目的是为了外面能then收到里面的resolve
    axios('register', {
      data: {
        id: data.id,
        pass: pass,
        repeatPass: repeatPass,
        language: data.language,
        info: data
      }
    })
      .then((response) => {
        console.log('/register', response);
        axios('login', { data: { id: data.id, pass: pass } })
          .then((res) => {
            console.log('/login', res);
            if (res !== false) {
              dispatch({
                type: types.FETCH_LOGIN,
                data: {
                  jwt: res,
                  user: {
                    _id: response._id,
                    username: 'visitor',
                    email: 'visitor',
                    info: data
                  },
                  isGuestLogin: true,
                  role: 'Visitor'
                }
              });
              // getTimezone(data.timezone);
              cb && cb(response);
              initChatRequest(dispatch);
            } else {
              message.error('请重新尝试');
            }
          })
          .catch((error) => {
            console.log(error);
          });
        cb && cb(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
//更新用户资料
export function updateUserInfoFetch(data, cb) {
  console.log('updateUser===', { data });
  return (dispatch) => {
    // 目的是为了外面能then收到里面的resolve
    const info = {};
    const role = data.role || "Visitor"
    const keys = Object.keys(data);
    keys.map((key) => {
      if (key !== '_id') {
        info[key] = data[key];
      }
    });

    axios('updateUser', { path: `${role}/${data._id}`, data: { info } })
      .then((response) => {
        console.log('/updateUser', response);
        const userInfo = response;
        dispatch({
          type: types.UPDATE_USERINFO,
          data: { _id: userInfo._id, ...userInfo.info }
        });
        dispatch(getTimezone(userInfo.info.timezone));
        cb && cb(userInfo);
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
//用户登录
export function loginFetch(data, cb) {
  return (dispatch) => {
    // 目的是为了外面能then收到里面的resolve
    axios('login', { data: data })
      .then((response) => {
        if (response && response != "false") {
          const decoded = JWT.decode(response);
          axios('getUserDetail', { path: decoded._id })
            .then((res) => {
              // if (CODES[res.language]) {
              //   i18next.changeLanguage(CODES[res.language][0]);
              //   dispatch(changeLanguage(CODES[res.language][1]));
              // }
              dispatch({
                type: types.FETCH_LOGIN,
                data: {
                  jwt: response,
                  user: {
                    _id: res._id,
                    username: res.info.username || res.id,
                    email: res.id,
                    firstname: res.info.firstname || "",
                    lastname: res.info.lastname || "",
                    role: res.role,
                    ...res.info
                  },
                  isGuestLogin: false,
                  role: res.role,
                  url: res['3DUrl']
                }
              });
              dispatch(getTimezone(res.info.timezone));
              cb && cb(response);
              initChatRequest(dispatch);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          message.error(i18next.t('translations.invalid-username'));
        }
        // Promise.resolve(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
//获取用户信息
export function getUserDetail(data, cb) {
  return (dispatch) => {

    axios('getUserDetail', { path: data._id })
      .then((res) => {
        console.log(res, 'michael')
        // if (CODES[res.language]) {
        //   i18next.changeLanguage(CODES[res.language][0]);
        //   dispatch(changeLanguage(CODES[res.language][1]));
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
// 忘记密码，邮箱验证
export function userForgotPassword(data) {
  console.log('UserForgotPassword', data);
  // 模拟数据
  // dispatch({
  //   type: types.FETCH_LOGIN,
  //   data: data,
  // });
  return () => {
    // resolve(data)
    // 告诉调用代码不需要再等待。
    return Promise.resolve(data);
  };
  return (dispatch) => {
    // 目的是为了外面能then收到里面的resolve
    return requestAPI({
      path: 'Login/UserForgotPassword',
      method: 'POST',
      params: data
    })
      .then((response) => {
        // dispatch({
        //   type: types.FETCH_LOGIN,
        //   data: response
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
// 退出
export const exitLoginStatus = () => {
  return {
    type: types.EXIT_LOGIN
  };
};
// 登录人聊天信息
export const LOG_CHART_USER = (value) => {
  return {
    type: types.LOG_CHART_USER,
    data: value
  };
};
export const LOG_PUBLIC_CHART_USER = (value) => {
  return {
    type: types.LOG_PUBLIC_CHART_USER,
    data: value
  };
};
export const BOOTH_CAHT_LOG_PUBLIC_CHART_USER = (value) => {
  return {
    type: types.BOOTH_CAHT_LOG_PUBLIC_CHART_USER,
    data: value
  };
};
// 打开关闭聊天
export const CHAT_SWITCH = (value) => {
  return {
    type: types.CHAT_SWITCH,
    data: value,
  };
};
