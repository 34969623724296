import React, { Component } from 'react';
import { Row, Col, Rate, Card, message } from 'antd';
import './BriefcaseItem.less';
import {
  CalendarFilled
} from '@ant-design/icons';
import noprofilepic from '../../../Image/no-profile-pic.png';
import axios from '../../../network/axios/$axios';
import get from 'lodash/get';
import { dateToStringTitle, formatTimezone, getTime } from '../../../Utils/Util';
import i18next from 'i18next';
import ContactInfoModal from '../../Networking/components/ContactInfoModal';
import { RiHeartFill } from 'react-icons/ri';

class BriefcaseItem extends Component {
  constructor(props) {
    super();
    this.state = {
      zones: {},
      isModalVisible: false,
      modelItem: {}
    };
  }
  componentDidMount() {
    axios('zoneBanners')
      .then((response) => {
        const data = response.data;
        const zones = {}
        data.forEach(item => zones[item._id] = item.display_name || item.name)
        this.setState({ zones })
      })
      .catch((error) => {
        console.log(error);
      });
  }
  // 删除事件
  deleteAction = (item) => {
    const { fetchBriefcase } = this.props;
    axios('deleteBriefcases', { path: item._id })
      .then((response) => {
        console.log('/deleteBriefcases=', response);
        message.success(i18next.t('translations.bookmark-deleted-success'));
        fetchBriefcase();
      })
      .catch((error) => {
        fetchBriefcase();
        console.log(error);
      });
  };
  // 点击agenda
  clickAgendaItem = (item) => {
    this.props.history.push({
      pathname: '/DetailDemand/' + item.refId,
      state: { params: item }
    });
  };
  // 跳转创建会议日程页面
  goBusinessmatching = (item) => {
    this.props.history.push(
      '/businessmatching/createmeeting/' + item._id
    );
  };
  // 点击network
  clickNetworkItem = (item) => {
    this.setState({ isModalVisible: true, modelItem: item.detail });
  };
  // 隐藏个人信息弹窗
  makeModalHiddle = () => {
    this.setState({ isModalVisible: false });
  };
  //跳转Visit Booth页面
  makeComfirm = () => {
    this.props.history.push('/ExpoHall/Booth/61e82adc1a22ff9af99387dc');
  };
  //点击Booth
  clickBoothItem = (item) => {
    this.props.history.push({
      pathname: '/ExpoHall/Booth/' + item.refId,
      state: { params: item }
    });
  };

  render() {
    const { isModalVisible, modelItem } = this.state;
    const { data, type, primary, language, zoneNumber } = this.props;
    if (type === 'event_agenda') {
      return (
        <div className='BriefcaseItem'>
          <div className="list-content pointer">
            <Row align="middle" justify='space-between'>
              <div className="asi-big-title pB10 pT10">
                {get(get(data, 'detail.display_name'), language)}
              </div>

              <div className='btn-area' style={{ backgroundColor: primary }} onClick={() => this.clickAgendaItem(data)}>
                <span>{i18next.t('translations.view')}</span>
              </div>

            </Row>
            <div
              className="clamp3"
              dangerouslySetInnerHTML={{
                __html: get(get(data, 'detail.display_description'), language)
              }}
            />
            <div>
              <CalendarFilled className='mR10' />{dateToStringTitle(formatTimezone(get(data, 'detail.startDate'), zoneNumber)) + ', ' + getTime(formatTimezone(get(data, 'detail.startDate'), zoneNumber))}
            </div>
            <Row align='middle' justify='space-between' style={{ marginTop: '2rem' }}>
              <div />
              <div
                className='hp-wish-button hp-cursor-pointer hp-border-radius-round remix-icon hp-p-8 hp-rate hp-text-color-danger-1 hp-bg-color-danger-4 hp-bg-color-dark-danger'
                onClick={() => this.deleteAction(data)}
              >
                <RiHeartFill />
              </div>
            </Row>
          </div>
        </div>
      )
    }
    if (type === 'network') {

      return (
        <div className='BriefcaseItem'>
          <div className="list-content pointer">
            <div className='flex-row'>
              <img className="noprofilepic" src={noprofilepic} />
              <div className='pL20' style={{ width: '100%' }}>
                <Row align="middle" justify='space-between'>
                  <div className="asi-big-title">
                    {get(data, 'detail.info.lastname') + ' ' + get(data, 'detail.info.firstname')}
                  </div>
                  <div className='btn-area' style={{ backgroundColor: primary }} onClick={() => this.clickNetworkItem(data)}>
                    <span>{i18next.t('translations.view')}</span>
                  </div>
                </Row>
                <div className='f16'>
                  {get(data, 'detail.role')}
                </div>
              </div>
            </div>
            <Row style={{ marginTop: '2rem' }}>
              <Col span={12} offset={0}>
                <div className='flex-row'>
                  <span>
                    {i18next.t('translations.company-name')}：
                  </span>
                  <span>
                    {get(data, 'detail.info.CompanyName')}
                  </span>
                </div>
              </Col>
              <Col span={12}>
                <div className='flex-row'>
                  <span>
                    {i18next.t('translations.job-title')}：
                  </span>
                  <span>
                    {get(data, 'detail.info.jobTitle')}
                  </span>
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: '2rem' }}>
              <Col span={12} offset={0}>
                <div className='flex-row'>
                  <span>
                    {i18next.t('translations.email')}：
                  </span>
                  <span>
                    {get(data, 'detail.info.email')}
                  </span>
                </div>
              </Col>
              <Col span={12}>
                <div className='flex-row'>
                  <span>
                    {i18next.t('translations.contact')}：
                  </span>
                  <span>
                    {get(data, 'detail.info.mobile')}
                  </span>
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: '2rem' }}>
              <Col span={12} offset={0}>
                <div className='flex-row'>
                  <span>
                    {i18next.t('translations.location')}：
                  </span>
                  <span>
                    {get(data, 'detail.info.position')}
                  </span>
                </div>
              </Col>
            </Row>
            {/* <Row align='middle' justify='space-between' style={{ marginTop: '2rem' }}>
                <div className='btn-area' style={{ backgroundColor: '#1E9AD6' }} onClick={() => this.goBusinessmatching(data)}>
                  <span>{i18next.t('translations.requestMeet')}</span>
                </div>
                <div
                  className='hp-wish-button hp-cursor-pointer hp-border-radius-round remix-icon hp-p-8 hp-rate hp-text-color-danger-1 hp-bg-color-danger-4 hp-bg-color-dark-danger'
                  onClick={() => this.deleteAction(data)}
                >
                  <RiHeartFill />
                </div>
              </Row> */}
          </div>
          <ContactInfoModal
            isModalVisible={isModalVisible}
            handleCancel={this.makeModalHiddle}
            handleOk={this.makeComfirm}
            modelItem={modelItem}
          />
        </div>
      )
    }
    if (type === 'exhibitor_booths') {
      return (
        <Card className="hp-text-center BriefcaseItem">
          <div className="list-content1 pointer">
            <Row className="recommend-bottom-btn">
              <div
                className='hp-wish-button hp-cursor-pointer hp-border-radius-round remix-icon hp-p-8 hp-rate hp-text-color-danger-1 hp-bg-color-danger-4 hp-bg-color-dark-danger'
                onClick={() => this.deleteAction(data)}
              >
                <RiHeartFill />
              </div>
            </Row>
            <Row className='img-area' onClick={() => this.clickBoothItem(data)}>
              <img
                className="recommend-product-img"
                src={get(data, 'detail.hallLogo.src')}
                alt=""
              />
            </Row>
            <Row className="recommend-bottom-title hp-mb-0">
              <span className='span'>
                {get(get(data, 'detail.display_name'), language)}
              </span>
            </Row>
          </div>
        </Card>
      )
    }
    return (
      <div className="BriefcaseItem">
        <div className="list-content pointer">
          <Row align="middle" justify='space-between'>
            <Row align="middle">
              <img className="noprofilepic" src={noprofilepic} />
              <div className="asi-big-title pL20">
                {get(get(data, 'detail.display_name'), language)}
              </div>
            </Row>
            <div className='f20' style={{ color: '#1E9AD6', fontWeight: 'bold' }} onClick={() => this.clickBoothItem(data)}>
              {i18next.t('translations.view')}
            </div>
          </Row>
          <div
            className="clamp3"
            dangerouslySetInnerHTML={{
              __html: get(get(data, 'detail.display_description'), language)
            }}
          />
          <Row align='middle' justify='space-between' style={{ marginTop: '2rem' }}>
            <div />
            <div
              className='hp-wish-button hp-cursor-pointer hp-border-radius-round remix-icon hp-p-8 hp-rate hp-text-color-danger-1 hp-bg-color-danger-4 hp-bg-color-dark-danger'
              onClick={() => this.deleteAction(data)}
            >
              <RiHeartFill />
            </div>
          </Row>
        </div>
      </div>
    );
  }
}

export default BriefcaseItem;
