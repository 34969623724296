import React, { Component } from 'react';
import { Tabs, List, message } from 'antd';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import BriefcaseItem from './components/BriefcaseItem';
import { getBriefcasesList } from '../../actions/BriefcaseAction';
import { dateToStringTitle, enterCurrentPage, formatTimezone } from '../../Utils/Util';
import './Briefcase.less';
import UsersList from '../BusinessMatching/components/UsersList';
import apiAxios from '../../network/axios/$axios';
import TabSpeakerPaneItemAll from '../Agenda/components/TabSpeakerPaneItemAll';
const { TabPane } = Tabs;
class Briefcase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      speakers: []
    };
  }
  componentDidMount() {
    this.getBriefcaseslist();
    this.getSpeakerByIds();
    enterCurrentPage();
  }

  // 查询所有speaker
  getSpeakerByIds = () => {
    apiAxios('getAllSpeaker', { params: {} })
      .then((response) => {
        const speakers = response.data.sort(function (a, b) { return a.seq - b.seq });
        this.setState({ speakers: speakers })
        // 缓存
        sessionStorage.setItem('ALLSpeakers', JSON.stringify(speakers));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 重新请求briefcases列表
  getBriefcaseslist = () => {
    const { userInfo, getBriefcasesList } = this.props;
    getBriefcasesList(userInfo.id);
  };

  componentWillReceiveProps(nextProps) {
    const keyss = Object.keys(nextProps);
    if (this.props.briefcasesData !== nextProps.briefcasesData && keyss.length > 0) {
      this.setState({ data: nextProps.briefcasesData })
    }
  }

  // 删除事件
  deleteAction = (item) => {
    apiAxios('deleteBriefcases', { path: item._id })
      .then((response) => {
        console.log('/deleteBriefcases=', response);
        message.success(i18next.t('translations.bookmark-deleted-success'));
        this.getBriefcaseslist();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { data, speakers } = this.state;
    console.log(data, "etsetse")
    const { primary, language, zoneNumber, userInfo } = this.props;
    const list = data['network'] ? data['network'] : [];
    const arr = [];
    list.map(obj => {
      arr.push({
        ...obj.detail,
        ...obj.detail.info,
        _id: obj._id,
      })
    })
    const list1 = data['event_agenda'] ? data['event_agenda'] : [];
    const arr1 = [];
    list1.map(obj => {
      arr1.push({
        ...obj.detail,
        _id: obj._id,
      })
    })
    const list2 = [];
    arr1.map(item => {
      const arr2 = list2.filter(v => v.key === dateToStringTitle(formatTimezone(item.startDate, zoneNumber)));
      if (arr2.length > 0) {
        const info = arr2[0];
        const datas = info.data;
        datas.push(item);
      } else {
        list2.push({
          title: dateToStringTitle(formatTimezone(item.startDate, zoneNumber)),
          key: dateToStringTitle(formatTimezone(item.startDate, zoneNumber)),
          data: [item]
        })
      }
    });

    return (
      <div className="my-briefcase">
        <h2>{i18next.t('translations.my-bookmarks')}</h2>
        <Tabs>
          <TabPane
            tab={i18next.t('translations.agenda')}
            key="event_agenda"
          >
            {
              list2.map(data => {
                return (
                  <TabSpeakerPaneItemAll page='Briefcase' {...this.props} currentData={data} key={data.key} remove={this.deleteAction} eventAgendas={data['event_agenda'] || []} userInfo={userInfo} fetchBriefcase={this.getBriefcaseslist} primary={primary} language={language} speakers={speakers} />
                )
              })
            }
          </TabPane>
          <TabPane
            tab={i18next.t('translations.booth')}
            key="exhibitor_booths"
          >
            <List
              grid={{
                gutter: [32, 16],
                xs: 1,
                sm: 2,
                md: 4,
                lg: 4,
                xl: 4,
                xxl: 4
              }}
              style={{ marginTop: '2rem' }}
              dataSource={data['exhibitor_booths']?.filter(em => !em.detail?.boothType || em.detail?.boothType == "Booth")}
              renderItem={(item, index) => (
                <List.Item key={index}>
                  <BriefcaseItem type={'exhibitor_booths'} data={item} {...this.props} fetchBriefcase={this.getBriefcaseslist} primary={primary} language={language} zoneNumber={zoneNumber} />
                </List.Item>
              )}
            />
          </TabPane>

          <TabPane
            tab={i18next.t('translations.menu-gallery')}
            key="gallery"
          >
            <List
              grid={{
                gutter: [32, 16],
                xs: 1,
                sm: 2,
                md: 4,
                lg: 4,
                xl: 4,
                xxl: 4
              }}
              style={{ marginTop: '2rem' }}
              dataSource={data['exhibitor_booths']?.filter(em => em.detail?.boothType == "Gallery")}
              renderItem={(item, index) =>
              (
                <List.Item key={index}>
                  <BriefcaseItem type={'exhibitor_booths'} data={item} {...this.props} fetchBriefcase={this.getBriefcaseslist} primary={primary} language={language} zoneNumber={zoneNumber} />
                </List.Item>
              )
              }
            />
          </TabPane>
          {/* <TabPane
            tab={i18next.t('translations.menu-contact')}
            key="network"
          >
            <UsersList data={arr} visitors={data['network']} onChange={this.deleteAction} {...this.props} />
          </TabPane> */}
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.loginUserInfo.userInfo,
  briefcasesData: state.briefcases.briefcasesData,
  primary: state.profile.primary,
  language: state.profile.language,
  zoneNumber: state.loginUserInfo.zoneNumber, // 时区数字
});

const mapDispatchToProps = (dispatch) => ({
  getBriefcasesList: (userId) => {
    dispatch(getBriefcasesList(userId));
  }
});

const enhance = compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(Briefcase);
