import './ResultType.less';
import i18next from 'i18next';

const ResultType = (props) => {
  const { data, selectedType, searchType } = props;
  return (
    <div className="result-type-page">
      {data.map((item, index) => {
        let title = i18next.t('translations.all-result');
        if (item === 'Agenda') {
          title = i18next.t('translations.agenda')
        } else if (item === 'Booth') {
          title = i18next.t('translations.showcase')
        } else if (item === 'Speaker') {
          title = i18next.t('translations.speakers')
        }
        return (
          <div key={index}
            onClick={() => {
              selectedType(item);
            }}
            className={searchType === item ? 'cell pointer asi-selected' : "cell pointer"}>
            <span className="f16">{title}</span>
          </div>
        );
      })}
    </div>
  );
};

export default ResultType;
