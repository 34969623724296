import React, { useEffect, useState } from "react";
import './index.less';
import apiAxios from '../network/axios/$axios';
import Icon from '../Image/icon.png';
import { Button } from "antd";
import i18next from "i18next";
import { connect } from "react-redux";
let marginTop = null;
let marginLeft = null;

const Game = props => {
  const { link, language } = props;
  const [data, setData] = useState();
  const [resultData, setResultData] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isTargetVisible, setIsTargetVisible] = useState(true);
  const [tag, setTag] = useState();


  useEffect(() => {
    marginTop = Math.floor(Math.random() * 65 + 5);
    marginLeft = Math.floor(Math.random() * 75 + 10);
  }, []);

  useEffect(() => {
    apiAxios('gameLink', {
      data: {
        "link": link
      }
    })
      .then((response) => {
        // console.log('/gameLink=', response);
        setData(response);
        // 用于测试
        // setData({
        //   code:"634ad4a4de9574f6aad28768",
        //   image:"https://Biz Tech Forum 2022virtual.cyberport.hk/media/target.png"
        // })
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onClickHandle = () => {
    // apiAxios('gameLinkCatch', {
    //   data: {
    //     "link": link,
    //     "code": data?.code
    //   }
    // })
    //   .then((response) => {
    //     console.log('/gameLinkCatch=', response);
    setIsTargetVisible(false)
    setIsModalVisible(true);
    // if (response.success && response.score < 20) {
    // setResultData(response);
    setTag(1);
    //   } else if (response.success && response.score === 20) {
    //     setResultData(response);
    //     setTag(2);
    //   } else {
    //     setTag(0);
    //   }
    // })
    // .catch((error) => {
    //   console.log(error);
    // });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  }

  const href = {
    "en-us": 'https://cvcfreg.cyberport.hk/en/survey/',
    "zh-hant": "https://cvcfreg.cyberport.hk/tc/survey/",
    "zh-hans": "https://cvcfreg.cyberport.hk/sc/survey/"
  }
  if (!data?.code) return null;
  let title = null;
  let secondTitle = null;
  let describe = null;
  if (tag === 0) {
    title = i18next.t('translations.game-end');
    secondTitle = <span className="game-title">{i18next.t('translations.game-end-describe')}</span>;
  } else if (tag === 1) {
    title = i18next.t('translations.hurray');
    secondTitle = <span className="game-title">{i18next.t('translations.game-second-1')} <span style={{ color: '#004096' }}>{resultData?.score === 1 && language === 'en-us' ? 'a' : resultData?.score}</span> {resultData?.score === 1 ? i18next.t('translations.game-second-2') : i18next.t('translations.game-second-2p')}</span>;
    describe = <span className="game-des">{i18next.t('translations.game-describe')}</span>;
  } else if (tag === 2) {
    title = i18next.t('translations.congrats');
    secondTitle = <span className="game-title">{i18next.t('translations.game-second-3')} <span style={{ color: '#004096' }}>{resultData?.score}</span> {resultData?.score === 1 ? i18next.t('translations.game-second-2') : i18next.t('translations.game-second-2p')}</span>;
    describe = <span className="game-des">{i18next.t('translations.game-describe-1')} <a href={href[language]} target='_blank' style={{ color: '#004096' }}>{i18next.t('translations.game-describe-3')}</a> {i18next.t('translations.game-describe-2')}</span>;
  }
  return (
    <div className='game'>
      {isTargetVisible && <div
        className='game-img pointer'
        style={{ position: 'fixed', left: window.innerWidth * marginLeft / 100, top: window.innerHeight * marginTop / 100, zIndex: 9999 }}
        onClick={() => onClickHandle()}
      >
        <img src={data?.image} />
      </div>}
      {isModalVisible && <div className="modal-view" style={{ height: window.innerHeight }}>
        <div className="modal-content-view">
          <img src={Icon} className='icon' />
          <div className="flex-center">
            <span className="game-title">{title}</span>
            {secondTitle}
            {/* {resultData?.success && <span className="game-des">{describe}</span>} */}
            <span className="game-des">{describe}</span>
          </div>
          <Button
            className="buttom-btn"
            style={{
              fontWeight: 'bold',
              backgroundColor: '#004096',
              color: '#ffffff',
            }}
            onClick={() => handleCancel()}
          >
            {i18next.t('translations.confirm')}
          </Button>
        </div>
      </div>}
    </div>
  )
}

const mapStateToProps = (state) => ({
  language: state.profile.language
});

export default connect(mapStateToProps, null)(Game);