import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, Button, Divider } from 'antd';
import {
  CloseOutlined,
} from '@ant-design/icons';
import './ContactInfoModal.less';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import get from 'lodash/get';
import axios from '../../../network/axios/$axios';
import { connect } from 'react-redux';
import { CHAT_SWITCH } from '../../../actions/LoginUserAction';
import ChatMessage from '../../../components/ChatMessage';

const ContactInfoModal = (props) => {
  const { isModalVisible, handleCancel, handleOk, modelItem, primary, chatSwitch, dispatchChatSwitch } = props;
  const [channel_url, setChannelURL] = useState('');
  const [zoneName, setZoneName] = useState('');
  const listTitle = "hp-p1-body";
  const listResult = "hp-mt-sm-4 hp-p1-body hp-text-color-black-100 hp-text-color-dark-0";
  const dividerClass = "hp-border-color-black-40 hp-border-color-dark-80";
  const codeMap = {
    'en-us': ['en', 'EN'],
    'zh-hant': ['hk', '繁'],
    'zh-hans': ['zh', '简']
  };
  const zoneCode = get(modelItem, 'info.timezone');

  let str = '';
  if (modelItem && modelItem.info && modelItem.info.firstname) {
    str = (modelItem.info.firstname).substring(0, 1);
  }

  const getOrCreatePrivateChat = (person) => {
    chartSwitch(false)
    axios('getOrCreatePrivateChat', {
      data: {
        target_user_id: person._id
      }
    })
      .then((response) => {
        if (response == "") {
          const timeout = setTimeout(() => {
            axios('getOrCreatePrivateChat', {
              data: {
                target_user_id: person._id
              }
            }).then((response2) => {
              setChannelURL(response2.channel_url);
              props.dispatchChatSwitch(true);
            });
          }, 500)
        } else {
          setChannelURL(response.channel_url);
          props.dispatchChatSwitch(true);
        }
      });
  };

  const chartSwitch = () => {
    dispatchChatSwitch(false);
  };

  useEffect(() => {
    if (zoneCode) {
      axios('registrationFormChoices', {
        path: zoneCode
      }).then((res) => {
        console.log('/registrationFormChoices', res);
        // name: '(UTC+08:00) Ulaanbaatar'
        setZoneName(res.name);
      });
    }
  }, [zoneCode])

  return (
    <Modal
      width={800}
      title={<div style={{ padding: '15px 20px', color: '#000000' }}>{i18next.t('translations.info')}</div>}
      visible={isModalVisible}
      footer={
        modelItem.role !== 'Visitor' && <Button
          style={{
            background: '#1E9AD6',
            color: 'white',
            fontWeight: 'bold',
            borderRadius: '15px'
          }}
          onClick={() => handleOk()}
        >
          {i18next.t('translations.visit-booth')}
        </Button>
      }
      onCancel={handleCancel}
      closeIcon={<CloseOutlined />}
    >
      <Row className="hp-bg-color-black-0 hp-bg-color-dark-100 hp-border-radius hp-pr-sm-16 hp-pr-32 contact-info-modal">
        <Col flex="240px" className="hp-profile-menu hp-py-12">
          <div className="hp-w-100">
            <div className="hp-profile-menu-header hp-text-center">
              <div className="flex-row-center hp-mb-16">
                <div className='flex-row-center role'>
                  <span>
                    {get(modelItem, 'role') === 'Visitor'
                      ? i18next.t('translations.visitor')
                      : i18next.t('translations.exhibitor')}
                  </span>
                </div>
              </div>

              <div className="flex-row-center">
                <div className="flex-row-center" style={{ minWidth: 80, width: 80, height: 80, borderRadius: 40, backgroundColor: '#C4E2FC' }}>
                  <span className="f30">{str}</span>
                </div>
              </div>

              <h2 className="hp-mt-24 hp-mb-4">{get(modelItem, 'info.username')}</h2>
              <h3 className="hp-p1-body">
                {get(modelItem, 'info.jobTitle')}
              </h3>
              <h4 className="hp-p1-body hp-mb-32">
                {get(modelItem, 'info.CompanyName')}
              </h4>

              <Button
                type='primary'
                style={{
                  fontWeight: 'bold',
                  width: '180px'
                }}
                onClick={() => getOrCreatePrivateChat(modelItem)}
              >
                {i18next.t('translations.start-chat')}
              </Button>
            </div>
          </div>
        </Col>

        <Col
          flex="1 1"
          className="hp-pl-sm-16 hp-pl-32 hp-pb-24 hp-overflow-hidden"
        >
          <Col span={24} className="hp-profile-content-list hp-pb-sm-0 hp-pb-12">
            <h2>{i18next.t('translations.personal-information')}</h2>
            <ul>
              <li>
                <span className={listTitle}>Full Name</span>
                <span className={listResult}>{get(modelItem, 'info.lastname')} {get(modelItem, 'info.firstname')}</span>
              </li>

              <li className="hp-mt-18">
                <span className={listTitle}>Display Name</span>
                <span className={listResult}>{get(modelItem, 'info.displayName')}</span>
              </li>

              <li className="hp-mt-18">
                <span className={listTitle}>Email</span>
                <a className={listResult} href={'mailto:' + get(modelItem, 'info.email')}>
                  {get(modelItem, 'info.email')}
                </a>
              </li>

              <li className="hp-mt-18">
                <span className={listTitle}>Phone</span>
                <a className={listResult} href={`tel:+${get(modelItem, 'info.mobile')}`}>
                  {get(modelItem, 'info.mobile')}
                </a>
              </li>

              <li className="hp-mt-18">
                <span className={listTitle}>Address</span>
                <span className={listResult}>{get(modelItem, 'info.country')}{get(modelItem, 'info.city')}{get(modelItem, 'info.address1')}{get(modelItem, 'info.address2')}</span>
              </li>
            </ul>
          </Col>

          <Divider className={dividerClass} />

          <Row align="middle" justify="space-between">
            <Col span={24}>
              <h3>{i18next.t('translations.additional-info')}</h3>
            </Col>

            <Col span={24} className="hp-profile-content-list hp-mt-sm-8 hp-mt-12">
              <ul>
                <li>
                  <span className={listTitle}>Language</span>
                  <span className={listResult}>{get(modelItem, 'info.language') && codeMap[get(modelItem, 'language')] && codeMap[get(modelItem, 'language')][1]}</span>
                </li>

                <li className="hp-mt-18">
                  <span className={listTitle}>Timezone</span>
                  <span className={listResult}>{zoneName}</span>
                </li>
              </ul>
            </Col>
          </Row>

        </Col>
      </Row>
      {(chatSwitch) && <ChatMessage selected_channel_url={channel_url} close={() => chartSwitch(false)} />}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  primary: state.profile.primary,
  chatSwitch: state.loginUserInfo.chatSwitch,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchChatSwitch: (flag) => {
    dispatch(CHAT_SWITCH(flag));
  }
});

const enhance = compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps));

export default enhance(ContactInfoModal);
