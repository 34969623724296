// import React from 'react';
import React, { useEffect, useState } from 'react';
import { Row, List, Col, Rate } from 'antd';
import './RecommendationList.less';
import {
  EnvironmentOutlined,
  ScheduleOutlined,
  StarOutlined,
  WechatOutlined
} from '@ant-design/icons';
import noProfilePic from '../../../Image/no-profile-pic.png';
import i18next from 'i18next';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import axios from '../../../network/axios/$axios';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { CHAT_SWITCH } from '../../../actions/LoginUserAction'
import ChatMessage from '../../../components/ChatMessage';

const RecommendationList = (props) => {
  const { onClickMeet, primary, chatSwitch, onChange, visitors, selectedRole, searchType, keyword } = props;
  const [allData, setAllData] = useState([]);
  const [peoples, setPeoples] = useState([]);
  const [channel_url, setChannelURL] = useState('');

  useEffect(() => {
    peoplerecommendation();
  }, []);

  useEffect(() => {
    if (selectedRole !== '') {
      const list = allData.filter(obj => obj.role === selectedRole);
      if (keyword !== '') {
        searchByKeyword(list);
      } else {
        setPeoples(list);
      }
    } else {
      if (keyword !== '') {
        searchByKeyword();
      } else {
        setPeoples(allData);
      }
    }
  }, [selectedRole, searchType, keyword]);
  // 轮播数据
  const peoplerecommendation = () => {
    axios('peoplerecommendation', {})
      .then((response) => {
        // console.log('peoplerecommendation', response);
        setPeoples(response.data);
        setAllData(response.data);
      });
  };
  // chat-use
  const getOrCreatePrivateChat = (person) => {
    chartSwitch(false)
    axios('getOrCreatePrivateChat', {
      data: {
        target_user_id: person._id
      }
    })
      .then((response) => {
        if (response == "") {
          const timeout = setTimeout(() => {
            axios('getOrCreatePrivateChat', {
              data: {
                target_user_id: person._id
              }
            }).then((response2) => {
              setChannelURL(response2.channel_url);
              props.dispatchChatSwitch(true);
            });
          }, 500)
        } else {
          setChannelURL(response.channel_url);
          props.dispatchChatSwitch(true);
        }
      });
  };
  const chartSwitch = (swith) => {
    props.dispatchChatSwitch(false);
  };
  const searchByKeyword = (arr) => {
    const datas = arr ? arr : allData;
    let list = [];
    if (searchType === 'all') {
      list = datas.filter(obj => (obj.info.firstname && obj.info.lastname && (obj.info.lastname + obj.info.firstname).indexOf(keyword) > -1) || (obj.info.jobTitle && obj.info.jobTitle.indexOf(keyword) > -1) || (obj.info.CompanyName && obj.info.CompanyName.indexOf(keyword) > -1) || (obj.info.CountryId && obj.info.CountryId.indexOf(keyword) > -1));
    } else if (searchType === 'name') {
      list = datas.filter(obj => (obj.info.firstname && obj.info.lastname && (obj.info.lastname + obj.info.firstname).indexOf(keyword) > -1));
    } else if (searchType === 'job') {
      list = datas.filter(obj => (obj.info.jobTitle && obj.info.jobTitle.indexOf(keyword) > -1));
    } else if (searchType === 'company') {
      list = datas.filter(obj => (obj.info.CompanyName && obj.info.CompanyName.indexOf(keyword) > -1));
    } else if (searchType === 'country') {
      list = datas.filter(obj => (obj.info.CountryId && obj.info.CountryId.indexOf(keyword) > -1));
    }
    setPeoples(list);
  };

  return (
    <div className="recommendation-page">
      <List
        grid={{
          gutter: [16, 16],
          xs: 1,
          sm: 2,
          md: 3,
          lg: 3,
          xl: 3,
          xxl: 3
        }}
        dataSource={peoples}
        renderItem={(obj, index) => {
          const arr = visitors.filter(v => v.refId === obj._id);
          const isSelected = arr.length > 0;
          return (
            <List.Item key={index}>
              <div className="list-card">
                {!get(obj, 'info.CompanyName') ? (
                  <Row style={{ height: '1.5rem' }} />
                ) : (
                  <Row>
                    <div className="type" style={{ backgroundColor: primary }}>
                      {/* <span style={{ fontSize: 12 }}>eClinics</span> */}
                      <span style={{ fontSize: 12 }}>{get(obj, 'info.CompanyName')}</span>
                    </div>
                  </Row>
                )}
                <Row className="pT10">
                  <Col md={4}>
                    <img className="user" src={noProfilePic} />
                  </Col>
                  <Col md={12} style={{ textAlign: 'left' }}>
                    <span className="username">{get(obj, 'info.lastname')}{get(obj, 'info.firstname')}</span>
                    <div>
                      <div className="description">
                        {/* <span>Standard Chartered Bank</span> */}
                        <span>{get(obj, 'info.jobTitle')}</span>
                      </div>
                      {/* <div>
                        <span>ED</span>
                      </div> */}
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="flex flex_a_c flex_j_c type contact-type">
                      <EnvironmentOutlined
                        style={{
                          color: '#ffffff',
                          marginRight: 5
                        }}
                      />
                      <span style={{ fontSize: 12 }}>
                        {/* {index === 2
                          ? i18next.t('translations.visitor')
                          : i18next.t('translations.exhibitor')} */}
                        {get(obj, 'role')}
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row
                  justify="space-between"
                  style={{
                    paddingLeft: '2rem',
                    paddingRight: '2rem',
                    marginTop: '2rem'
                  }}
                >
                  <Col>
                    <div
                      className="pointer flex flex_d_c flex_a_c"
                    >
                      <Rate style={{ width: '3rem', marginTop: '-15px' }} onChange={(e) => onChange(obj, arr, e)} count="1" value={isSelected ? 1 : 0} />
                      <span style={{ marginTop: '-10px' }}>{i18next.t('translations.bookmark')}</span>
                    </div>
                  </Col>
                  {get(obj, 'role') === 'Visitor' && (
                    <Col>
                      <div
                        onClick={() => onClickMeet()}
                        className="pointer flex flex_d_c"
                      >
                        <ScheduleOutlined />
                        <span>{i18next.t('translations.meet')}</span>
                      </div>
                    </Col>
                  )}
                  <Col>
                    <div onClick={() => getOrCreatePrivateChat(obj)} className="pointer flex flex_d_c">
                      <WechatOutlined />
                      <span>{i18next.t('translations.chat')}</span>
                    </div>
                  </Col>
                </Row>
              </div>
            </List.Item>
          )
        }}
      />
      {(chatSwitch) && <ChatMessage selected_channel_url={channel_url} close={() => chartSwitch(false)} />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  // primary: state.profile.primary,
  // language: state.profile.language,
  chatSwitch: state.loginUserInfo.chatSwitch,
});
const mapDispatchToProps = (dispatch) => ({
  dispatchChatSwitch: (flag) => {
    dispatch(CHAT_SWITCH(flag));
  }
});
const enhance = compose(withTranslation('translations'));

// export default enhance(RecommendationList);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(enhance(RecommendationList));