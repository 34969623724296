import React, { useState, useCallback, useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useSelector, useDispatch } from 'react-redux';
import { CLEAR_WS_MSG, LOCATION_ONLINE_USERS } from '../../constants/ActionTypes';

export const WebSocket = (props) => {
    const { url } = props
    const webSocketMessage = useSelector(state => state.webSocket)
    const dispatch = useDispatch()
    const { sendMessage, lastJsonMessage, readyState } = useWebSocket(url);
    useEffect(() => {
        if (webSocketMessage.message && webSocketMessage.message !== "") sendMessage(webSocketMessage.message)

        console.log("webSocketMessage", webSocketMessage.message)
        dispatch({ type: CLEAR_WS_MSG })
    }, [webSocketMessage?.message])
    const handleMessage = ({
        get_location_users: (data) => {
            dispatch({ type: LOCATION_ONLINE_USERS, data })
        }
    })
    useEffect(() => {
        if (!lastJsonMessage) return
        console.log("lastJsonMessage", lastJsonMessage)
        handleMessage[lastJsonMessage.type]?.call({}, lastJsonMessage.data)

    }, [lastJsonMessage]);

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    return (
        <div style={{ display: "none" }} id="Websocket-debug">
            connection: {connectionStatus}
            lastJsonMessage: {JSON.stringify(lastJsonMessage)}

        </div>
    );
};