import React, { PureComponent, Component } from 'react';
import { Button, message, Modal, Row, Timeline } from 'antd';
import { connect } from 'react-redux';
import './TabSpeakerPaneItem.less';
import {
  UsergroupAddOutlined,
  YoutubeOutlined,
  PlusOutlined,
  MinusOutlined,
  CloseOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import axios from '../../../network/axios/$axios';
import { compareDate } from '../../../Utils/Util';
import Zoom from '../../../components/Zoom';
import get from 'lodash/get';
import i18next from 'i18next';
import { getTime } from '../../../Utils/Util';
import { withRouter } from 'react-router-dom';
import SpeakerList from './AgItemSpeakerList';
import { CHANGE_AGENDA_CURRENTINDEX, JOINDETAILDEMANDITEM } from '../../../constants/ActionTypes';
import { RiHeartFill } from "react-icons/ri";

class TabSpeakerPaneItem extends Component {
  constructor(props) {
    super();
    this.state = {
      currentData: {},
      agendaList: [],
      refersh: false,
      fetching: true,
      visiable: false,
      linkType: '',
      linkHref: '',
      modalItem: {}
    };
  }
  componentDidMount() { }
  openModal = (item) => {
    if (item.target === '_self') {
      // 当前页面iframe打开，保留上下头部信息
      // this.props.isSelfCallback(item);
      this.props.UPDATE_SELF_IFRAME_ITEM(item);
      this.props.history.push(`/embeddedPage`);
      return;
    }
    if (item.target !== '_popup' && item.target !== 'zoom') {
      return;
    }
    // 保存内容类型：linkType供popup使用, 暫時有iframe, zoom, video, image, 如果沒有設置默認為用iframe直接打開
    this.setState({
      visiable: true,
      linkType: get(item, 'linkType'),
      linkHref: get(item, 'href'),
      modalItem: item
    });
  };

  handleCancel = () => {
    this.setState({
      visiable: false
    });
  };
  clickItem(item) {
    // this.props.history.push({
    //   pathname: '/DetailDemand/' + item._id,
    //   state: { params: item }
    // });
    this.clickUpload('detail ' + item.name);
    this.clickJoin(item);
  }
  clickJoin(item) {
    const { currentData } = this.props;
    // this.props.history.push({
    //   pathname: '/JoinDetailDemand/' + item._id,
    //   state: { params: item }
    // });
    this.props.history.push({
      pathname: '/JoinDetailDemand',
    });
    item.timetitle = currentData.title;
    // sessionStorage.setItem('JoinDetailDemandItem', JSON.stringify(item));
    // 当前agenda
    this.props.CHANGE_AGENDA_CURRENTINDEX(item.index);
    // 当前下标
    this.props.JOINDETAILDEMANDITEM(item);
    // 当前天的列表
    sessionStorage.setItem(
      'currentDataAgendaList',
      JSON.stringify(currentData)
    );
  }

  onChange = (data, arr, value) => {
    const { isGuestLogin, fetchBriefcase } = this.props;
    if (value === 0) {
      // 删除Briefcase
      axios('deleteBriefcases', { path: arr[0]._id })
        .then((response) => {
          message.success(i18next.t('translations.bookmark-deleted-success'));
          fetchBriefcase();
        })
        .catch((error) => {
          console.log(error);
        });
      this.clickUpload('delete Briefcases ' + arr[0].detail.name);
    } else {
      if (isGuestLogin) {
        this.goRegisterPage();
      } else {
        // 新增Briefcase
        axios('addBriefcases', {
          data: {
            model: 'Agenda/Agenda',
            refId: data._id
          }
        })
          .then((response) => {
            message.success(i18next.t('translations.bookmark-added-success'));
            fetchBriefcase();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      this.clickUpload('add Briefcases ' + data.name);
    }
  };
  //统计点击事件
  clickUpload = (fieldname) => {
    axios('activity', {
      data: {
        event: '[Click] Agenda ' + fieldname
      }
    }).then((response) => {
      console.log('/activity', response);
    });
  };
  //跳转注册页面
  goRegisterPage = () => {
    this.props.history.push('/register');
  };
  // 匹配speakers
  filterSpeakers = (allSpeakers = [], speakerIds = []) => {
    const speakerArr = [];
    speakerIds.forEach(id => {
      if (allSpeakers.find(speaker => speaker._id === id)) {
        speakerArr.push(allSpeakers.find(speaker => speaker._id === id));
      }
    });
    return speakerArr;
  };
  renderButton = (data) => {
    const { language } = this.props;
    /*canJoinAfter > now = coming soon
      canJoinAfter < now < endDate = Live
      endDate < now = if don't have video >> session ended, if have video >> playbac*/
    const timeA = data.mustJoinBefore || data.startDate;
    const timeB = data.canJoinAfter || data.endDate;
    console.log('timeA-timeB', timeA, timeB);
    // 第一个大，返回true
    const cominGsoonFlag = compareDate(timeA, new Date());
    const liveFlag = compareDate(timeB, new Date());
    console.log('timeA-timeB', cominGsoonFlag, liveFlag);
    // const cominGsoonFlag = compareDate(data.canJoinAfter, new Date());
    // const liveFlag = compareDate(data.endDate, new Date());
    if (cominGsoonFlag) {
      return (
        <Button
          className={'agendaBtn' + ' play ' + 'antBtnRound mR20'}
          size="large"
          style={{ fontSize: 16 }}
        >
          {i18next.t('translations.coming-soon')}
        </Button>
      );
    } else if (liveFlag && data.type === 'Live') {
      data.isJoin = true;
      return (
        <Button
          className={'antBtnRound' + ' details'}
          size="large"
          onClick={() => this.clickItem(data)}
        >
          {i18next.t('translations.join-now')}
        </Button>
      );
    } else if (data.type === 'Recordings') {
      return (
        <Button
          className={'antBtnRound' + ' details'}
          size="large"
          onClick={() => this.clickItem(data)}
        >
          {i18next.t('translations.play-now')}
        </Button>
      );
    } else {
      return (
        <Button
          className={'agendaBtn' + ' ended ' + 'antBtnRound mR20'}
          size="large"
        >
          {i18next.t('translations.session-ended')}
        </Button>
      );
    }
  };
  // 废弃
  renderButtonDestroy = (data) => {
    const { language } = this.props;
    /*canJoinAfter > now = coming soon
      canJoinAfter < now < endDate = Live
      endDate < now = if don't have video >> session ended, if have video >> playbac*/
    // const cominGsoonFlag = compareDate(data.canJoinAfter, new Date());
    // const liveFlag = compareDate(data.endDate, new Date());
    const timeA = data.mustJoinBefore || data.startDate;
    const timeB = data.canJoinAfter || data.endDate;
    const cominGsoonFlag = compareDate(timeA, new Date());
    const liveFlag = compareDate(timeB, new Date());
    if (cominGsoonFlag) {
      return (
        <Button
          className={'agendaBtn' + ' play ' + 'antBtnRound mR20'}
          size="large"
        >
          {i18next.t('translations.coming-soon')}
        </Button>
      );
    } else if (liveFlag && data.type === 'Live') {
      // 正在播放
      if (data.liveLink && data.liveLink.length) {
        return data.liveLink.map((video, index) => (
          <a
            href={
              video.target === '_popup' ||
                video.target === 'zoom' ||
                video.target === '_self'
                ? null
                : video.href
            }
            onClick={() => this.openModal(video)}
            target="_blank"
            rel="noreferrer"
            key={video.title}
            className={'mR20'}
          >
            <Button
              className={'agendaBtn live antBtnRound'}
              icon={<YoutubeOutlined />}
              size="large"
            >
              {video.display_title[language]}
            </Button>
          </a>
        ));
      }
    } else {
      // session ended:但是有videos，可以回放
      if (data.type === 'Recordings' && data.videos && data.videos.length) {
        const videosArr = data.videos.map((video, index) => (
          <a
            href={
              video.target === '_popup' ||
                video.target === 'zoom' ||
                video.target === '_self'
                ? null
                : video.href
            }
            onClick={() => this.openModal(video)}
            target="_blank"
            rel="noreferrer"
            key={video.title}
            className={'mR20'}
          >
            <Button
              className={'agendaBtn' + ' play ' + 'antBtnRound'}
              icon={<YoutubeOutlined />}
              size="large"
            >
              {get(video.display_title, language)}
            </Button>
          </a>
        ));
        return videosArr;
      } else {
        return (
          <Button
            className={'agendaBtn' + ' ended ' + 'antBtnRound mR20'}
            size="large"
          >
            {i18next.t('translations.session-ended')}
          </Button>
        );
      }
    }
  };
  renderButtonDestroy2 = (data) => {
    const { language } = this.props;
    /*canJoinAfter > now = coming soon
      canJoinAfter < now < endDate = Live
      endDate < now = if don't have video >> session ended, if have video >> playbac*/
    const timeA = data.mustJoinBefore || data.startDate;
    const timeB = data.canJoinAfter || data.endDate;
    // 第一个大，返回true
    const cominGsoonFlag = compareDate(timeA, new Date());
    const liveFlag = compareDate(timeB, new Date());
    // const cominGsoonFlag = compareDate(data.canJoinAfter, new Date());
    // const liveFlag = compareDate(data.endDate, new Date());
    if (cominGsoonFlag) {
      return (
        <Button
          className={'agendaBtn' + ' play ' + 'antBtnRound mR20'}
          size="large"
        >
          Coming Soon
        </Button>
      );
    } else if (liveFlag) {
      data.isJoin = true;
      return (
        <Button
          onClick={() => this.clickJoin(data)}
          className={'agendaBtn live antBtnRound mR20'}
          icon={<UsergroupAddOutlined />}
          size="large"
        >
          Join
        </Button>
      );
    } else {
      return (
        <Button
          className={'agendaBtn' + ' ended ' + 'antBtnRound mR20'}
          size="large"
        >
          {i18next.t('translations.session-ended')}
        </Button>
      );
    }
  };
  renderDownloadButton(data) {
    const { language } = this.props;
    return (
      data.downloads &&
      data.downloads.map((download) => (
        <a
          href={
            download.target === '_popup' ||
              download.target === 'zoom' ||
              download.target === '_self'
              ? null
              : download.href
          }
          download={download.title}
          target={download.target}
          rel="noreferrer"
          key={download.title}
          className="mR20"
          onClick={() => this.openModal(download)}
        >
          <Button
            className={'mR20' + 'antBtnRound'}
            icon={<DownloadOutlined />}
            size="large"
          >
            {download.display_title[language]}
          </Button>
        </a>
      ))
    );
  }
  renderLeft(data, arr, isSelected) {
    const { addMysession, remove, primary, language, selectIndex, speakers, page, speakerId } =
      this.props;
    // const currentSpeakers = speakers.filter(
    //   (speaker) => data.speakers.indexOf(speaker._id) !== -1
    // );
    // 按顺序过滤
    data.event_speakers = this.filterSpeakers(speakers, data.speakers);

    // data.event_speakers = currentSpeakers;
    return (
      <div>
        <div className="flex flex_j_l flex_a_c">
          {/* <VideoCameraFilled
            style={{
              color: data.type === 'Recordings' ? '#28a745' : '#17a2b8',
              marginRight: 10
            }}
          /> */}
          <span className={data.type === 'Recordings' ? 'type type-rec' : 'type type-live'}>{data.type}</span>
        </div>
        {data.type !== 'Recordings' && <div className='date-start-end'>
          {/* {getTime(data.mustJoinBefore)} - {getTime(data.canJoinAfter)} */}
          {getTime(data.startDate)} - {getTime(data.endDate)} (HKT)
        </div>}
        <div className="f30 bold pB10 pT20">
          {get(get(data, 'display_name'), language)}
        </div>
        <div className="pB20">
          <div
            dangerouslySetInnerHTML={{
              __html: get(get(data, 'display_description'), language)
            }}
          />
        </div>
        <div className='pB20 '>
          {/* <Button
            className={'antBtnRound' + ' details'}
            size="large"
            onClick={() => this.clickItem(data)}
          >
            {i18next.t('translations.details')}
          </Button> */}
          {this.renderButton(data)}
        </div>
        <div>
          {/* speaker进入，不展示 */}
          {!speakerId && data.event_speakers?.length > 0 && <SpeakerList data={data.event_speakers}></SpeakerList>}
        </div>
        {page !== 'Briefcase' && (
          <div>
            {/* {this.renderButton(data)} */}
            {/* {this.renderDownloadButton(data)} */}
            {/* {selectIndex === 1 || data.mySessionId ? <Button className={'removeBtn' + ' mR20 ' + 'antBtnRound'} icon={<MinusOutlined />} size="large" onClick={remove && remove.bind(null, data)}>Remove</Button> : <Button className={'agendaBtn' + ' mR20 ' + 'antBtnRound'} icon={<PlusOutlined />} size="large" onClick={addMysession && addMysession.bind(null, data)} style={{ backgroundColor: primary, borderColor: primary }}>{i18next.t('translations.add-to-my-session')}</Button>} */}
            {/* <Button
            className={'antBtnRound' + ' mR20 details'}
            size="large"
            onClick={() => this.clickItem(data)}
          >
            {i18next.t('translations.details')}
          </Button> */}
            {/* <Rate
            onChange={this.onChange.bind(this, data, arr)}
            className="mR20"
            count="1"
            value={isSelected ? 1 : 0}
          /> */}
          </div>
        )}
        <div className='RiHeartFill'>
          {
            isSelected ? (
              <div
                className='hp-wish-button hp-cursor-pointer hp-border-radius-round remix-icon hp-p-8 hp-rate hp-text-color-danger-1 hp-bg-color-danger-4 hp-bg-color-dark-danger'
                // onClick={() => dispatch(removeFromWishlist(value.id))}
                onClick={() => {
                  if (page === 'Briefcase') {
                    remove(data);
                  } else {
                    this.onChange(data, arr, 0)
                  }
                }}
              >
                <RiHeartFill />
              </div>
            ) : (
              <div
                className='hp-wish-button hp-cursor-pointer hp-border-radius-round remix-icon hp-p-8 hp-rate hp-text-color-black-40 hp-text-color-dark-70 hp-bg-color-black-10 hp-bg-color-dark-90'
                // onClick={() => dispatch(addToWish(value.id, value))}
                onClick={this.onChange.bind(this, data, arr)}
              >
                <RiHeartFill />
              </div>
            )
          }
        </div>
      </div>
    );
  }
  // 根据getTime(data.startDate)时间排序
  sortByStartDate(data) {
    const list = data.sort((a, b) => {
      if (new Date(a.startDate) === new Date(b.startDate)) {
        return a.seq - b.seq;
      } else {
        return new Date(a.startDate) - new Date(b.startDate);
      }
    });
    const array1 = [];
    const array2 = [];
    list.map(obj => {
      const time1 = obj.mustJoinBefore || obj.startDate;
      const time2 = obj.canJoinAfter || obj.endDate;
      const cominGsoonFlag = compareDate(time1, new Date());
      const liveFlag = compareDate(time2, new Date());
      if (cominGsoonFlag || (liveFlag && obj.type === 'Live')) {
        array1.push(obj);
      } else {
        array2.push(obj);
      }
    });
    return [...array1, ...array2];
  }
  render() {
    const { currentData, eventAgendas, primary, language, page, newDate } =
      this.props;
    const { visiable, linkType, linkHref, modalItem } = this.state;
    if (currentData.data && currentData.data.length === 0) {
      return null;
    }
    return (
      <div className="TabPaneItem">
        <div className="TabSpeakerPaneItem">
          <h4>{currentData.title}</h4>
          <Timeline>
            {currentData.data &&
              this.sortByStartDate(currentData.data).map((data, index) => {
                data.index = index;
                const arr = eventAgendas.filter(
                  (obj) => obj.refId === data._id
                );
                const isSelected = arr.length > 0 || page === 'Briefcase';
                /*canJoinAfter > now = coming soon
              canJoinAfter < now < endDate = Live
              endDate < now = if don't have video >> session ended, if have video >> playbac*/
                // 第一个大，返回true
                const timeA = data.mustJoinBefore || data.startDate;
                const timeB = data.canJoinAfter || data.endDate;
                const cominGsoonFlag = compareDate(timeA, new Date());
                const liveFlag = compareDate(timeB, new Date());
                return (
                  <Timeline.Item
                  // label={getTime(data.startDate)}
                  // color={data.type === 'Recordings' ? '#28a745' : '#17a2b8'}
                  >
                    {(data.type !== 'Recordings' && (cominGsoonFlag || (liveFlag && data.type === 'Live'))) && <div>{getTime(data.startDate)}</div>}
                    <div
                      key={index}
                      className="item"
                      style={{ ...data.style }}>
                      {this.renderLeft(data, arr, isSelected)}
                      {visiable && (
                        <Modal
                          visible={visiable}
                          width={800}
                          title={
                            <div
                              style={{
                                backgroundColor: primary,
                                padding: '15px 20px'
                              }}
                            >
                              {get(modalItem, `display_title[${language}]`)}
                            </div>
                          }
                          footer={null}
                          closeIcon={
                            <CloseOutlined style={{ color: 'white' }} />
                          }
                          onCancel={this.handleCancel}
                          bodyStyle={{ padding: 0 }}
                        >
                          {linkType === 'zoom' ||
                            linkType === 'iframe' ||
                            linkType === undefined ? (
                            linkType === 'iframe' &&
                              linkHref.indexOf('.pdf') > -1 ? (
                              <iframe
                                src={linkHref}
                                frameBorder="0"
                                style={{ width: '100%', height: 800 }}
                              ></iframe>
                            ) : (
                              <Zoom link={linkHref} />
                            )
                          ) : (
                            <Row align="middle">
                              {linkType === 'video' ? (
                                <video
                                  autoPlay
                                  playsInline //解决手机端无法自动播放功能
                                  loop
                                  style={{ width: '100%', height: '100%' }}
                                  src={linkHref}
                                />
                              ) : (
                                <img
                                  src={linkHref}
                                  style={{ width: '100%', height: '100%' }}
                                />
                              )}
                            </Row>
                          )}
                        </Modal>
                      )}
                    </div>
                  </Timeline.Item>
                );
              })}
          </Timeline>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isGuestLogin: state.loginUserInfo.isGuestLogin
});
const mapDispatchToProps = (dispatch) => ({
  UPDATE_SELF_IFRAME_ITEM: (item) => {
    dispatch({
      type: 'UPDATE_SELF_IFRAME_ITEM',
      data: item
    });
  },
  // 保存当前点击的agenda下标
  CHANGE_AGENDA_CURRENTINDEX: (item) => {
    dispatch({
      type: CHANGE_AGENDA_CURRENTINDEX,
      data: item
    });
  },
  // 当前点击的agenda
  JOINDETAILDEMANDITEM: (item) => {
    dispatch({
      type: JOINDETAILDEMANDITEM,
      data: item
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TabSpeakerPaneItem));
